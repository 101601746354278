import {whenDomLoaded} from "./core-utils/whenDomLoaded";

export function installPhoneMask(root: HTMLElement, phoneMaskApplicator: (root: HTMLElement) => void) {
    whenDomLoaded(function () {
        phoneMaskApplicator(root);
        trackNodeForUpdates(root, phoneMaskApplicator);
    });
}

// similar to protectPhoneTailNodesOf but for addedNodes and for one root node
function trackNodeForUpdates(root: Node, phonesMaskApplicator: (node: HTMLElement) => void) {
    // Select the node that will be observed for mutations
    // const targetNode = document.body;

    // Options for the observer (which mutations to observe)
    const config = {attributes: false, childList: true, subtree: true};

    // Callback function to execute when mutations are observed
    const callback = function (mutationsList: MutationRecord[], observer: MutationObserver) {
        // Support IE11 via traditional for loop
        for (let j = 0; j < mutationsList.length; ++j) {
            const mutation = mutationsList[j];
            if (mutation.type === 'childList') {
                for (let i = 0; i < mutation.addedNodes.length; ++i) {
                    const node = mutation.addedNodes[i];
                    if (node instanceof HTMLElement) {
                        phonesMaskApplicator(node);
                    }
                }
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(root, config);

    // Later, you can stop observing
    // observer.disconnect();
}
