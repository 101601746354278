import {findPhoneLinksIn} from "./phone-link-utils/phone-link-utils";
import {disassembleBrokenPhoneLinksOnPage} from "./phone-link-utils/disassemble-broken-phone-links-on-page";
import {searchAndAssemblePhonesIntoLinksRight} from "./phone-link-utils/parse-phones";
import {markPhoneLinks} from "./phone-link-utils/mark-phone-links";

export function rebuildCorrectPhoneLinksOnThePage(root: HTMLElement) {
    const dirtyPhoneLinks = findPhoneLinksIn(root);
    disassembleBrokenPhoneLinksOnPage(dirtyPhoneLinks);
    searchAndAssemblePhonesIntoLinksRight(root);
    const cleanPhoneLinks = findPhoneLinksIn(root);
    markPhoneLinks(cleanPhoneLinks);
    return cleanPhoneLinks;
}
